import React from "react";
import { TestingIntro } from "./intro";
import { TestingPrepare } from "./prepare";
import { TestDetail } from "./test/test";
import { ECandidateAssessmenStatus, STEPS, TAssessmentTesting, TTesting } from "./type";
import io, { Socket } from "socket.io-client";
import { FinishTest } from "./test/finish_test";
import { TakeBreak } from "./test/take_break";
import { SubmitTest } from "./test/submit_test";
import { useTesting } from "./hooks/useTesting";
import { useParams } from "react-router-dom";
import { AbilitiesCheckAssessment } from "./test/receiving-email-link/abilities_check";
import { AbilitiesCheckConfirmation } from "./test/receiving-email-link/abilities_check_confirmation";
import { CameraVerifyAssessment } from "./test/receiving-email-link/camera_verify";
import { AudioMicAssessment } from "./test/receiving-email-link/audio_mic";
import { ConfirmUserInvited } from "./test/receiving-email-link/confirm_user_invited";
import { ApiInstance } from "../../api";
import { ThankYouTest } from "./test/thank-you";
import { useCandidateLayoutContext } from "../../layout/candidate";
import { ERROR_CODE, ERROR_CODE_DESC_MESSAGE, ERROR_CODE_TITLE_MESSAGE } from "../../utils/constants";
import { ErrorLink } from "../../components/errorLink";
import { TCompanyProfile } from "../../api/company-profile";
import i18next from "../../i18n";
import { EAudioSetting, ECameraSetting } from "../my_assessment/create-assessment/type";

type TTestingPage = {
    step: STEPS;
    setStep: (step: STEPS) => void;

    url: string;
    assessmentDetails: TAssessmentTesting;
    setAssessmentDetails: (assessment: TAssessmentTesting) => void;

    sectionTesting: TTesting;
    setSectionTesting: (sectionTesting: TTesting) => void;
    handleFullScreen: any;
    socket: Socket<any, any> | undefined;
    companyProfile: TCompanyProfile | undefined;

    // token: string;
    setToken: (token: string) => void;
    openDrawer: boolean;
    setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
    isPinQuestionList: boolean;
    setIsPinQuestionList: React.Dispatch<React.SetStateAction<boolean>>;

    audioPlayedMap: Map<string, number>;
    setAudioPlayedMap: React.Dispatch<React.SetStateAction<Map<string, number>>>;
};
export const TestingPageContext = React.createContext<TTestingPage>({} as TTestingPage);
export const useTestingPageContext = () => React.useContext(TestingPageContext);

export const TestingPage: React.FC = () => {
    const { url = "" } = useParams();
    const { loadCandidateAssessmentByURL } = useTesting();
    const { handleFullScreen, companyProfile } = useCandidateLayoutContext();

    const [socket, setSocket] = React.useState<Socket<any, any> | undefined>();
    const [step, setStep] = React.useState<STEPS>(STEPS.HOLD);
    const [assessmentDetails, setAssessmentDetails] = React.useState<TAssessmentTesting>({} as TAssessmentTesting);
    const [sectionTesting, setSectionTesting] = React.useState<TTesting>({} as TTesting);
    const [token, setToken] = React.useState<string>("");
    const [errorCodeCandidate, setErrorCodeCandidate] = React.useState<number | undefined>(undefined);
    const [openDrawer, setOpenDrawer] = React.useState<boolean>(false);
    const [isPinQuestionList, setIsPinQuestionList] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (url) {
            loadCandidateAssessmentByURL(url, setAssessmentDetails, setErrorCodeCandidate);
        }
    }, [url, loadCandidateAssessmentByURL]);

    React.useEffect(() => {
        if (!socket) return;
        socket.on("connect", () => {
            console.warn("connected");
        });
    }, [socket]);

    React.useEffect(() => {
        if ((step === STEPS.INTRO && token) || (!socket && token && step === STEPS.SUBMIT_TEST)) {
            setSocket((prev) => {
                if (prev) return prev;
                const newSocket = io(process.env.REACT_APP_SOCKET_URL ?? "", {
                    path: "/api/socket.io",
                    // transports: ['websocket'],
                    secure: true,
                    extraHeaders: {
                        Authorization: `Bearer ${token}`
                    }
                    // rejectUnauthorized: false,
                });
                return newSocket;
            });
        }
    }, [step, token, socket]);

    React.useEffect(() => {
        return () => {
            if (socket) {
                console.warn("disconnect");
                socket.disconnect();
            }
        };
    }, []);

    const setTokenOnSocket = React.useCallback((token: string) => {
        // Add bearer token to socket
        setToken(token);
        ApiInstance.setToken(token);
    }, []);

    React.useEffect(() => {
        if (!assessmentDetails || !assessmentDetails._id) return;
        
        const isDisabledCamera = assessmentDetails.assessment.cameraSetting === ECameraSetting.DISABLED;
        const isDisabledAudio = assessmentDetails.assessment.audioSetting === EAudioSetting.DISABLED;

        console.log({ isDisabledCamera });

        if (assessmentDetails.testCandidateToken) {
            setTokenOnSocket(assessmentDetails.testCandidateToken);
        }
        if (assessmentDetails.status === ECandidateAssessmenStatus.DONE) {
            setStep(assessmentDetails?.isSubmitted ? STEPS.END_TEST : STEPS.SUBMIT_TEST);
            return;
        }

        if (assessmentDetails.hasIssue === true && assessmentDetails.isApproved === false) {
            setStep(STEPS.ABILITIES_CHECK_CONFIRMATION);
            return;
        }
        if (!assessmentDetails.testCandidateToken) {
            setStep(STEPS.CONFIRM_USER_INVITED);
            return;
        }
        if (!assessmentDetails.hasSubmitIssue) {
            setStep(STEPS.ABILITIES_CHECK);
            return;
        }
        if (assessmentDetails.testCandidateToken && !isDisabledCamera) {
            setStep(STEPS.CAMERA_CHECK);
            return;
        }

        if (assessmentDetails.testCandidateToken && !isDisabledAudio) {
            setStep(STEPS.AUDIO_MIC_CHECK);
            return;
        }

        setStep(STEPS.INTRO);
    }, [assessmentDetails, setTokenOnSocket]);

    const onGoingTest = React.useCallback(() => {
        setStep(STEPS.TEST);
    }, [setStep]);

    const titleError = React.useMemo(() => {
        if (errorCodeCandidate === ERROR_CODE.LOCKED) {
            return ERROR_CODE_TITLE_MESSAGE[errorCodeCandidate];
        }
        return undefined;
    }, [errorCodeCandidate]);
    const descriptionError = React.useMemo(() => {
        if (errorCodeCandidate === ERROR_CODE.LOCKED) {
            return ERROR_CODE_DESC_MESSAGE[errorCodeCandidate];
        }
        return undefined;
    }, [errorCodeCandidate]);
    const [audioPlayedMap, setAudioPlayedMap] = React.useState<Map<string, number>>(new Map());

    return (
        <TestingPageContext.Provider
            value={{
                step,
                setStep,
                url,
                assessmentDetails,
                setAssessmentDetails,
                sectionTesting,
                setSectionTesting,
                socket,
                setToken,
                handleFullScreen,
                companyProfile,
                openDrawer,
                setOpenDrawer,
                isPinQuestionList,
                setIsPinQuestionList,
                audioPlayedMap,
                setAudioPlayedMap
            }}
        >
            <div className="lg-container mx-auto rounded-[12px] flex flex-col justify-center items-center">
                {errorCodeCandidate === ERROR_CODE.EXPIRED || errorCodeCandidate === ERROR_CODE.LOCKED ? (
                    <ErrorLink title={titleError} description={descriptionError} />
                ) : errorCodeCandidate ? (
                    <div className="flex bg-white lg:max-w-[562px] rounded-[12px] p-4 lg:p-8 shadow-e-03 box-border overflow-hidden">
                        <div className="flex flex-col gap-[24px]">
                            <h2 className="text-heading-5-bold text-high-em mb-0">
                                {i18next.t("testing.assessmentLinkExpired")}
                            </h2>
                            <p className="text-standard text-high-em mb-0">
                                {i18next.t("testing.sorryForTheInconvenience")}
                            </p>
                            <p className="text-standard text-high-em mb-0">
                                {i18next.t("testing.contact")}{" "}
                                <a href="mailto:contact@husa.io" className="text-standard text-primary-bold mb-0">
                                    contact@husa.io
                                </a>
                            </p>
                        </div>
                    </div>
                ) : null}
                <div className="flex items-center justify-center w-full">
                    {step === STEPS.CONFIRM_USER_INVITED && <ConfirmUserInvited />}
                    {step === STEPS.ABILITIES_CHECK && <AbilitiesCheckAssessment />}
                    {step === STEPS.ABILITIES_CHECK_CONFIRMATION && <AbilitiesCheckConfirmation />}
                    {step === STEPS.CAMERA_CHECK && <CameraVerifyAssessment />}
                    {step === STEPS.AUDIO_MIC_CHECK && <AudioMicAssessment />}

                    {/* start Testing */}
                    {step === STEPS.INTRO && <TestingIntro />}
                    {step === STEPS.PREPARE && <TestingPrepare onNext={onGoingTest} />}
                    {step === STEPS.TEST && <TestDetail />}
                    {step === STEPS.FINISH_TEST && <FinishTest />}
                    {step === STEPS.TAKE_BREAK && <TakeBreak />}
                    {step === STEPS.SUBMIT_TEST && <SubmitTest />}
                    {step === STEPS.END_TEST && <ThankYouTest />}
                </div>
            </div>
        </TestingPageContext.Provider>
    );
};
