import React from "react";
import { GeneralAssessment } from "./general";
import { STEPS } from "../type";
import { useParams } from "react-router-dom";
import { TAssessment } from "../../my_assessment/create-assessment/type";
import { useTesting } from "../hooks/useTesting";

type TReceivingLinkPage = {
    step: STEPS;
    setStep: (step: STEPS) => void;
    assessmentDetails: TAssessment;
    setAssessmentDetails: (assessment: TAssessment) => void;
};
export const ReceivingLinkPageContext = React.createContext<TReceivingLinkPage>({} as TReceivingLinkPage);
export const useReceivingLink = () => React.useContext(ReceivingLinkPageContext);

export const ReceivingLinkPage: React.FC = () => {
    const { assessmentUrl = "" } = useParams();
    const { loadAssessmentByURL } = useTesting();
    const [step, setStep] = React.useState<STEPS>(STEPS.GENERAL);
    const [assessmentDetails, setAssessmentDetails] = React.useState<TAssessment>({} as TAssessment);

    React.useEffect(() => {
        if (assessmentUrl) {
            loadAssessmentByURL(assessmentUrl, setAssessmentDetails);
        }
    }, [assessmentUrl, loadAssessmentByURL]);

    return (
        <ReceivingLinkPageContext.Provider
            value={{
                step,
                setStep,
                assessmentDetails,
                setAssessmentDetails
            }}
        >
            <div className="lg-container mx-auto rounded-[12px] flex justify-center items-center">
                <div className="flex items-center justify-center">
                    {step === STEPS.GENERAL && <GeneralAssessment />}
                </div>
            </div>
        </ReceivingLinkPageContext.Provider>
    );
};
