import classNames from "classnames";
import { Iframe } from "../../../components/iframe";
import i18next from "../../../i18n";
import { TLikertQuestion } from "../../create_test/tab/question/type";
import React from "react";
import { useLikertQuestion } from "../../create_test/tab/hooks/useLikertQuestion";

type TLikertQuestionRendererProps = {
    question: TLikertQuestion;
    onChangeCorrectAnswer: (answer: undefined | string) => void;
    currentQuestionIndex: number;
    correctAnswer?: string;
};

export const LikertQuestionRenderer: React.FC<TLikertQuestionRendererProps> = ({
    question,
    currentQuestionIndex,
    correctAnswer,
    onChangeCorrectAnswer
}) => {
    const onAnswerChange = (answer: string) => {
        onChangeCorrectAnswer(answer);
    };

    const { displayOption } = useLikertQuestion();

    return (
        <div className="flex flex-col gap-3">
            <div className="flex flex-col py-[32px] px-2 lg:px-[48px] rounded-[12px] bg-white shadow-e-03 box-border overflow-hidden gap-[56px]">
                <div className="w-full">
                    <div className="pl-[24px] flex flex-col gap-[24px] border-l-[3px] border-[#C6BBFF]">
                        <div className="text-standard-medium text-primary-bold">
                            {i18next.t("testing.currentQuestion")} {currentQuestionIndex}
                        </div>
                        <Iframe content={(question?.content as string) || ""} />
                    </div>
                </div>
            </div>
            <div className="w-full p-6 bg-white border-[1px] border-[#EEF2F6] space-y-4 lg:space-y-0 lg:flex items-center gap-6 rounded-xl shadow-shadow-md">
                {question.options.map((option) => {
                    return (
                        <LikertQuestionOptions
                            key={option.content}
                            isSelected={correctAnswer === option.content}
                            onClick={() => onAnswerChange(option.content)}
                        >
                            {displayOption(option)}
                        </LikertQuestionOptions>
                    );
                })}
            </div>
        </div>
    );
};

type TLikertQuestionOptions = {
    isSelected?: boolean;

    children?: React.ReactNode;

    onClick?: () => void;
};

const LikertQuestionOptions: React.FC<TLikertQuestionOptions> = ({ isSelected = false, children, onClick }) => {
    const onClickOption = () => {
        if (!onClick) {
            return;
        }

        onClick();
    };

    return (
        <div
            className={classNames([
                "flex items-center justify-center bg-white h-[51px] w-full py-4 px-3 rounded-lg border-[1px] border-[#EEF2F6] shadow-shadow-xs cursor-pointer",
                {
                    "!border-[2px] !border-[#0DA578] !bg-[#ECFDF3]": isSelected
                }
            ])}
            onClick={onClickOption}
        >
            <span className="text-[14px] font-medium text-center">{children}</span>
        </div>
    );
};
