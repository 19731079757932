import React from "react";
import { EAudioSetting, ECameraSetting, TAssessment } from "../create-assessment/type";
import { Radio, RadioChangeEvent, Switch } from "antd";
import i18next from "../../../i18n";
import { HRCheckBox } from "../../../components/form/checkbox";

type AssessmentConfigurationProps = {
    assessmentDetails: TAssessment;
    onUpdate: (data: Record<string, any>) => void;
};
export const AssessmentConfiguration: React.FC<AssessmentConfigurationProps> = ({ assessmentDetails, onUpdate }) => {
    const CAMERA_SETTINGS_OPTIONS = [
        { label: i18next.t("assessmentDetail.cameraSettings.required"), value: ECameraSetting.REQUIRED },
        { label: i18next.t("assessmentDetail.cameraSettings.optional"), value: ECameraSetting.OPTIONAL },
        { label: i18next.t("assessmentDetail.cameraSettings.disabled"), value: ECameraSetting.DISABLED }
    ];

    const AUDIO_SETTINGS_OPTIONS = [
        { label: i18next.t("assessmentDetail.audioSettings.required"), value: EAudioSetting.REQUIRED },
        { label: i18next.t("assessmentDetail.audioSettings.optional"), value: EAudioSetting.OPTIONAL },
        { label: i18next.t("assessmentDetail.audioSettings.disabled"), value: EAudioSetting.DISABLED }
    ];

    return (
        <div className="min-h-[250px] flex flex-col w-full items-start justify-start rounded-[12px] bg-white shadow-e-03 z-[3] p-4 gap-4">
            <span className="text-standard-bold text-g-800">{i18next.t("assessmentDetail.configuration")}</span>

            <div className="flex flex-col gap-2">
                <span className="text-body text-high-em">{i18next.t("assessmentDetail.forceTurnOnCamera")}:</span>
                <div className="flex gap-4">
                    <Radio.Group
                        value={assessmentDetails.cameraSetting}
                        options={CAMERA_SETTINGS_OPTIONS}
                        onChange={({ target: { value } }: RadioChangeEvent) => {
                            onUpdate({
                                cameraSetting: value
                            });
                        }}
                    />
                </div>
            </div>

            <div className="flex flex-col gap-2">
                <span className="text-body text-high-em">{i18next.t("assessmentDetail.forceTurnOnAudio")}:</span>
                <div className="flex gap-4">
                    <Radio.Group
                        value={assessmentDetails.audioSetting}
                        options={AUDIO_SETTINGS_OPTIONS}
                        onChange={({ target: { value } }: RadioChangeEvent) => {
                            onUpdate({
                                audioSetting: value
                            });
                        }}
                    />
                </div>
            </div>

            <div className="flex items-center justify-between space-x-2">
                <span className="text-body text-high-em">{i18next.t("assessmentDetail.requireLocation")}</span>
                <div>
                    <Switch
                        checked={assessmentDetails.isRequireLocation}
                        style={{
                            width: "30px"
                        }}
                        onChange={() =>
                            onUpdate({
                                isRequireLocation: !assessmentDetails.isRequireLocation
                            })
                        }
                    />
                </div>
            </div>
        </div>
    );
};
