import { ApiInstance } from "..";
import { TApproveTesting, TEvaluateEssayQuestion, TEvaluateMultiEssayQuestion, TSaveNote, TSearchCandidateList, TViewEssayQuestion } from "../../pages/my_assessment/type";
import { EEvaluateStatus, IReportIssueCandidate, IStartSection } from "../../pages/testing/type";
import { LANGUAGE } from "../../utils/constants";
import { convert2QueryString } from '../../utils/convert2QueryString';
import { getCurrentLanguage } from "../../utils/lang";

export const getCandidateAssessmentByURL = (url: string) => {
  return ApiInstance.getInstance().get(`/api/test-candidate/get-testing-by-url/${url}`);
}

export const reportIssueCandidate = (payload: IReportIssueCandidate) => {
  return ApiInstance.getInstance().post(`/api/test-execution/report-issue`, payload);
}

export const startSection = (payload: IStartSection) => {
  return ApiInstance.getInstance().post(`/api/test-execution/start-section`, payload);
}

export const approveTesting = (payload: TApproveTesting) => {
  return ApiInstance.getInstance().post(`/api/test-execution/confirm-candidate-condition`, payload);
}

export const getTestCandidateById = (id: string) => {
  return ApiInstance.getInstance().get(`/api/test-candidate/${id}`);
}

export const getListIdsByAssessent = (assessmentId: string) => {
  return ApiInstance.getInstance().get(`/api/test-candidate/get-list-ids-by-assessment/${assessmentId}`);
}

export const saveNoteTestCandidate = (id: string, payload: TSaveNote) => {
  return ApiInstance.getInstance().put(`/api/test-candidate/${id}`, payload);
}

export const saveQuestionNote = (id: string, questionId: string | undefined, payload: TSaveNote) => {
  return ApiInstance.getInstance().put(`/api/test-candidate/rating-additional-question/${id}/${questionId}`, payload);
}

export const downloadTestResult = (id: string) => {
  const lang = getCurrentLanguage();
  return ApiInstance.getInstance().get(`/api/test-candidate/download-test-result/${id}?lang=${lang}`);
}

export const getCurrentSection = (id: string) => {
  return ApiInstance.getInstance().get(`/api/test-execution/get-current-section/${id}`);
}

export const getCandidateList = (assessmentId: string, payload: TSearchCandidateList) => {
  const requestURL = `/api/test-candidate/get-test-candidate-by-assessment/${assessmentId}?` + convert2QueryString(payload);
  return ApiInstance.getInstance().get(requestURL);
}

export const rejectCandidate = (testCandidateId: string) => {
  return ApiInstance.getInstance().put(`/api/test-candidate/reject-test-candidate/${testCandidateId}`, {});
}

export const rejectMultiCandidate = (assessmentId: string, testCandidateIds: string[]) => {
  return ApiInstance.getInstance().put(`/api/test-candidate/reject-multiple-test-candidate/${assessmentId}`, {testCandidateIds});
}

export const sendResultCandidate = (testCandidateId: string) => {
  const lang = getCurrentLanguage();
  return ApiInstance.getInstance().post(`/api/test-candidate/send-result-to-candidate/${testCandidateId}?lang=${lang}`, {});
}

export const removeCandidate = (testCandidateId: string) => {
  return ApiInstance.getInstance().delete(`/api/test-candidate/remove-candidate/${testCandidateId}`);
};

export const removeMultiInvitation = (assessmentId: string, testCandidateIds: string[]) => {
  const payload = {
    testCandidateIds: testCandidateIds
  };
  return ApiInstance.getInstance().delete(`/api/test-candidate/remove-multiple-test-candidate/${assessmentId}`, { data : payload });
};

export const getMyTestCandidateId = (candidateId: string) => {
  return ApiInstance.getInstance().get(`/api/test-candidate/get-my-test-candidate-by-candidate/${candidateId}`);
}

export const checkStatusAssessment = (testCandidateId: string) => {
  return ApiInstance.getInstance().post(`/api/test-candidate/assert/${testCandidateId}`);
}

export const exportResultAssessment = (assessmentId: string, testCandidateIds: string[] = []) => {
  if (testCandidateIds.length > 0) {
    return ApiInstance.getInstance().post(`/api/test-candidate/export-result-of-all-candidate-by-assessments/${assessmentId}`, {testCandidateIds});
  }
  return ApiInstance.getInstance().post(`/api/test-candidate/export-result-of-all-candidate-by-assessments/${assessmentId}`);
}

export const isEnableExportResultAssessment = (assessmentId: string) => {
  return ApiInstance.getInstance().get(`/api/test-candidate/is-enable-export-results/${assessmentId}`);
}

export const evaluateEssayQuestion = (payload: TEvaluateEssayQuestion) => {
  return ApiInstance.getInstance().put(`/api/test-evaluation/evaluate-essay-question`, payload);
}

export const evaluateMultiEssayQuestion = (payload: TEvaluateMultiEssayQuestion) => {
  return ApiInstance.getInstance().put(`/api/test-evaluation/evaluate-multiple-essay-question`, payload);
}

export const setViewEssayQuestion = (payload: TViewEssayQuestion) => {
  return ApiInstance.getInstance().put(`/api/test-evaluation/view-essay-question`, payload);
}

export const updateEvaluateStatus = (id: string, evaluateStatus: EEvaluateStatus) => {
  return ApiInstance.getInstance().put(`/api/test-candidate/${id}/evaluate-status`, { evaluateStatus })
}
