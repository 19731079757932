import i18next from "../../../../i18n";
import { HRButton, IconButton } from "../../../../components/button";
import { ICClose, ICTick } from "../../../../icons";
import { createDefaultQuestion, EQuestionType, generateOption, IndexedOption, TLikertQuestion } from "./type";
import React from "react";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import { useCreateTestPageContext } from "../..";
import classNames from "classnames";
import { isEmptyHTML, toolbar } from "./editor.config";
import draftToHtml from "draftjs-to-html";
import { MAX_CONTENT_LENGTH } from "../../../../utils/constants";
import { getInnerText } from "./helper";
import { Modal } from "antd";
import { useLikertQuestion } from "../hooks/useLikertQuestion";

type LikertQuestion = {
    selectedQuestion: TLikertQuestion | undefined;
    questionType: EQuestionType.LIKERT;
    onCancel: () => void;
};

export const LikertQuestionCreator: React.FC<LikertQuestion> = ({ selectedQuestion, questionType, onCancel }) => {
    const [question, setQuestion] = React.useState<TLikertQuestion>(
        () => (selectedQuestion ?? createDefaultQuestion(questionType)) as TLikertQuestion
    );

    const { form, selectedSection, setShowAddQuestion, onCreatedQuestion } = useCreateTestPageContext();
    const [needCheck, setNeedCheck] = React.useState<boolean>(false);

    const [editorState, setEditorState] = React.useState<EditorState>(
        EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(question?.content).contentBlocks))
    );

    const { generateLikertOptions, displayOption } = useLikertQuestion();

    const onSelectAnswerAmount = (amount: 4 | 5 | 7) => {
        const options = generateLikertOptions(amount);

        setQuestion({
            ...question,
            amountOfAnswers: amount,
            options: options.map((option) => generateOption(option))
        });
    };

    const displayedOptions = React.useMemo(
        () => question.options.map((option) => displayOption(option.content)),
        question.options
    );

    const isDirty = React.useMemo(() => {
        if (!selectedQuestion)
            return (
                getInnerText(question.content).replace(/\n/g, "") !== "" ||
                getInnerText(question.referenceContent as string).replace(/\n/g, "") !== ""
            );
        return (
            question.content !== selectedQuestion?.content ||
            question.referenceContent !== selectedQuestion?.referenceContent
        );
    }, [question, selectedQuestion]);

    const [showCancelModal, setShowCancelModal] = React.useState<boolean>(false);

    const onCancelAction = React.useCallback(() => {
        if (isDirty) {
            setShowCancelModal(true);
        } else {
            onCancel();
        }
    }, [isDirty, setShowCancelModal, onCancel]);

    const onEditorStateChange = (editorState: EditorState) => {
        setEditorState(editorState);
        setQuestion({
            ...question,
            content: draftToHtml(convertToRaw(editorState.getCurrentContent()))
        });
    };

    const validationValues = (): boolean => {
        setNeedCheck(true);
        return isEmptyHTML(question.content) || isEmptyHTML(question.referenceContent as string);
    };

    const onSave = () => {
        if (!validationValues()) {
            onCreatedQuestion(question);
            setShowAddQuestion(false);
        }
    };

    const invalidMaxLengthEditor = React.useMemo(() => {
        const text = editorState?.getCurrentContent()?.getPlainText().length;
        if (text && text > MAX_CONTENT_LENGTH) {
            return true;
        }
        return false;
    }, [editorState]);

    const isQuestionError = React.useMemo(() => {
        return needCheck && (isEmptyHTML(question.content) || invalidMaxLengthEditor);
    }, [needCheck, question.content, invalidMaxLengthEditor]);

    const title = React.useMemo(() => {
        return editorState.getCurrentContent().getPlainText();
    }, [editorState]);

    return (
        <div className="pb-16 space-y-8 text-left lg-container mx-auto">
            <div className="lg:flex items-center justify-between">
                <div>
                    <div className="flex space-x-2 items-center font-bold text-[24px]">
                        <IconButton
                            btnType="sub"
                            btnSize="sm"
                            icon={<ICClose width={18} height={18} />}
                            onClick={onCancelAction}
                        />
                        <span className="lg:max-w-[500px] line-clamp-1">
                            {title || i18next.t("createTest.newQuestion")}
                        </span>
                    </div>
                    <span className="text-xs font-medium text-n-600 ml-12">
                        {form.getFieldValue("title")} / {selectedSection?.name ?? ""}
                    </span>
                </div>
                <div className="hidden lg:flex space-x-4">
                    <span>
                        <HRButton btnType="sub" btnSize="md">
                            <div className="flex justify-center">{i18next.t("createTest.cancel")}</div>
                        </HRButton>
                    </span>
                    {!selectedQuestion && (
                        <span>
                            <HRButton btnSize="md" btnType="sub">
                                <div className="flex justify-center">{i18next.t("createTest.saveAndCreateNew")}</div>
                            </HRButton>
                        </span>
                    )}
                    <span>
                        <HRButton btnSize="md" className="w-[107px] h-[44px]" onClick={onSave}>
                            <div className="flex space-x-2 justify-center">
                                <ICTick />
                                <span>
                                    {selectedQuestion ? i18next.t("createTest.update") : i18next.t("createTest.save")}
                                </span>
                            </div>
                        </HRButton>
                    </span>
                </div>
            </div>
            <div className="pt[12px] pb-[32px]">
                <div className="lg:flex bg-white rounded-[12px]">
                    <div className="flex flex-col w-full py-6 border-r-[1px] border-[#EEF2F6]">
                        <div className="py-4 px-6 border-b-[1px] border-[#EEF2F6]">
                            <span className="text-[18px] font-semibold">{i18next.t("createTest.question")}</span>
                        </div>
                        <div className="p-6">
                            <Editor
                                stripPastedStyles={true}
                                toolbar={toolbar}
                                editorState={editorState}
                                toolbarClassName={classNames(isQuestionError ? "error" : "", "editor-toolbar")}
                                wrapperClassName={classNames(
                                    isQuestionError ? "error" : "",
                                    "questions-wrapper",
                                    "editor-wrapper"
                                )}
                                editorClassName={classNames(
                                    isQuestionError ? "error" : "",
                                    "questions-editor",
                                    "editor-editor"
                                )}
                                onEditorStateChange={onEditorStateChange}
                                placeholder={i18next.t("createTest.enterQuestion") ?? ""}
                            />
                        </div>
                    </div>
                    <div className="py-6 w-[400px] min-w-[400px]">
                        <div className="py-4 px-6">
                            <span className="text-[18px] font-semibold">{i18next.t("createTest.selectedAnswer")}</span>
                        </div>

                        <div className="py-3 px-6 flex items-center justify-between bg-[#F6F8FB]">
                            <span className="text-[14px] font-medium">{i18next.t("createTest.amountOfAnswer")}</span>

                            <div className="flex gap-3">
                                <HRButton
                                    btnType="sub"
                                    btnSize="md"
                                    onClick={() => onSelectAnswerAmount(4)}
                                    style={{
                                        outline: "none",
                                        boxShadow: "none",
                                        borderColor: question.amountOfAnswers === 4 ? "#1A73E8" : "#D1D5DB"
                                    }}
                                >
                                    4
                                </HRButton>
                                <HRButton
                                    style={{
                                        outline: "none",
                                        boxShadow: "none",
                                        borderColor: question.amountOfAnswers === 5 ? "#1A73E8" : "#D1D5DB"
                                    }}
                                    btnType="sub"
                                    btnSize="md"
                                    onClick={() => onSelectAnswerAmount(5)}
                                >
                                    5
                                </HRButton>
                                <HRButton
                                    style={{
                                        outline: "none",
                                        boxShadow: "none",
                                        borderColor: question.amountOfAnswers === 7 ? "#1A73E8" : "#D1D5DB"
                                    }}
                                    btnType="sub"
                                    btnSize="md"
                                    onClick={() => onSelectAnswerAmount(7)}
                                >
                                    7
                                </HRButton>
                            </div>
                        </div>

                        <LikertQuestionOptions options={displayedOptions} />
                    </div>
                </div>
            </div>
            <div className="flex lg:hidden space-x-4">
                <span>
                    <HRButton btnType="sub" btnSize="md">
                        <div className="flex justify-center">{i18next.t("createTest.cancel")}</div>
                    </HRButton>
                </span>
                {!selectedQuestion && (
                    <span>
                        <HRButton btnSize="md" btnType="sub">
                            <div className="flex justify-center">{i18next.t("createTest.saveAndCreateNew")}</div>
                        </HRButton>
                    </span>
                )}
                <span>
                    <HRButton btnSize="md" className="w-[107px] h-[44px]" onClick={onSave}>
                        <div className="flex space-x-2 justify-center">
                            <ICTick />
                            <span>
                                {selectedQuestion ? i18next.t("createTest.update") : i18next.t("createTest.save")}
                            </span>
                        </div>
                    </HRButton>
                </span>
            </div>
            <Modal
                title={i18next.t("createTest.notification")}
                open={showCancelModal}
                centered={true}
                onCancel={setShowCancelModal.bind(null, false)}
                footer={
                    <div className="grid grid-cols-[auto_auto] justify-end gap-[16px]">
                        <HRButton btnSize="sm" btnType="sub" onClick={() => setShowCancelModal(false)}>
                            {i18next.t("createTest.no")}
                        </HRButton>
                        <HRButton btnSize="sm" onClick={onCancel}>
                            {i18next.t("createTest.yes")}
                        </HRButton>
                    </div>
                }
            >
                <div className="space-y-2">
                    <div>{i18next.t("createTest.infoWillBeLost")}</div>
                    <div>{i18next.t("createTest.sureToCancel")}</div>
                </div>
            </Modal>
        </div>
    );
};

type TLikertQuestionOptions = {
    options: string[];
};

const LikertQuestionOptions: React.FC<TLikertQuestionOptions> = ({ options = [] }) => {
    return (
        <div className="w-full p-6 flex flex-col gap-6">
            {options.map((option, index) => {
                return (
                    <div key={index} className="flex gap-3 items-center">
                        <span className="w-6 text-[16px] font-medium">#{index + 1}</span>

                        <span className="text-[16px] font-medium">{option}</span>
                    </div>
                );
            })}
        </div>
    );
};
