import classNames from "classnames";
import { Iframe } from "../../../components/iframe";
import { TLikertQuestionTesting, TSectionTesting, TTestingCandidate } from "../../testing/type";
import { IndexedOption } from "../../create_test/tab/question/type";
import { useLikertQuestion } from "../../create_test/tab/hooks/useLikertQuestion";

type TDetailLikertQuestionProps = {
    question: TLikertQuestionTesting;
    setQuestions: React.Dispatch<React.SetStateAction<TTestingCandidate[]>>;
    testCandidate: TTestingCandidate;
    candidateId: string;
    section: TSectionTesting;
};

export const DetailLikertQuestion: React.FC<TDetailLikertQuestionProps> = ({ question }) => {
    const { displayOption } = useLikertQuestion();

    const getOption = (option: IndexedOption): string => {
        if (typeof option === "string") {
            return option;
        }

        return option.content;
    };

    return (
        <div className="w-full flex flex-col gap-1">
            <Iframe content={question.content} />

            <div className="flex flex-wrap gap-2">
                {question.options.map((option, index) => {
                    return (
                        <DetailLikertQuestionOption key={index} isSelected={getOption(option) === question.answer}>
                            {displayOption(option)}
                        </DetailLikertQuestionOption>
                    );
                })}
            </div>
        </div>
    );
};

type TDetailLikertQuestionOption = {
    children?: React.ReactNode;

    isSelected?: boolean;
};

const DetailLikertQuestionOption: React.FC<TDetailLikertQuestionOption> = ({ children, isSelected }) => {
    return (
        <div
            className={classNames([
                "flex items-center justify-center bg-white h-[51px] py-2 px-1 rounded-lg border-[1px] border-[#EEF2F6] shadow-shadow-xs cursor-pointer",
                {
                    "!border-[2px] !border-[#0DA578] !bg-[#ECFDF3]": isSelected
                }
            ])}
        >
            <span className="text-[12px] font-medium">{children}</span>
        </div>
    );
};
