import { LANGUAGE } from "./constants";

export const getCurrentLanguage = () => {
    const lang = localStorage.getItem("language") ?? LANGUAGE.VN;

    if (lang === LANGUAGE.VN) {
        return "vi";
    }

    return lang;
};
