import i18next from "i18next";
import { ELikertOptions, IndexedOption } from "../question/type";

export const useLikertQuestion = () => {
    const optionsMap: Record<string, string> = {
        [ELikertOptions.STRONGLY_DISAGREE]: i18next.t("createTest.likertOptions.stronglyDisagree"),
        [ELikertOptions.DISAGREE]: i18next.t("createTest.likertOptions.disagree"),
        [ELikertOptions.SOMEWHAT_DISAGREE]: i18next.t("createTest.likertOptions.somewhatDisagree"),
        [ELikertOptions.NEUTRAL]: i18next.t("createTest.likertOptions.neutral"),
        [ELikertOptions.SOMEWHAT_AGREE]: i18next.t("createTest.likertOptions.somewhatAgree"),
        [ELikertOptions.AGREE]: i18next.t("createTest.likertOptions.agree"),
        [ELikertOptions.STRONGLY_AGREE]: i18next.t("createTest.likertOptions.stronglyAgree")
    };

    const generateLikertOptions = (amount: 4 | 5 | 7) => {
        if (amount === 5) {
            return [
                ELikertOptions.STRONGLY_DISAGREE,
                ELikertOptions.DISAGREE,
                ELikertOptions.NEUTRAL,
                ELikertOptions.AGREE,
                ELikertOptions.STRONGLY_AGREE
            ];
        }
        if (amount === 7) {
            return [
                ELikertOptions.STRONGLY_DISAGREE,
                ELikertOptions.DISAGREE,
                ELikertOptions.SOMEWHAT_DISAGREE,
                ELikertOptions.NEUTRAL,
                ELikertOptions.SOMEWHAT_AGREE,
                ELikertOptions.AGREE,
                ELikertOptions.STRONGLY_AGREE
            ];
        }
        return [
            ELikertOptions.STRONGLY_DISAGREE,
            ELikertOptions.DISAGREE,
            ELikertOptions.AGREE,
            ELikertOptions.STRONGLY_AGREE
        ];
    };

    const displayOption = (option: IndexedOption | string) => {
        if (typeof option === "string") {
            return optionsMap[option];
        }

        return optionsMap[option.content];
    };

    return {
        generateLikertOptions,
        displayOption
    };
};
