import { Divider, Typography } from "antd";
import React from "react";
import { ReactMic } from "react-mic";
import { HRSelect } from "../../../../components/form/select";
import { ICMicroPhone, ICNext, ICSound } from "../../../../icons";
import { HRButton } from "../../../../components/button";
import { useTestingPageContext } from "../..";
import { STEPS } from "../../type";
import styled from "styled-components";
import i18next from "../../../../i18n";
import { EAudioSetting } from "../../../my_assessment/create-assessment/type";

const WrapperDevices = styled.div`
    .ant-select-selection-item {
        white-space: normal;
        word-break: break-all;
    }
`;

export const AudioMicAssessment: React.FC = () => {
    const { setStep, companyProfile, assessmentDetails } = useTestingPageContext();
    const [speakersDeviceId, setSpeakersDeviceId] = React.useState({});
    const [microphoneDeviceId, setMicrophoneDeviceId] = React.useState({});
    const [speakersDevices, setSpeakersDevices] = React.useState<MediaDeviceInfo[]>([]);
    const [microphoneDevices, setMicrophoneDevices] = React.useState<MediaDeviceInfo[]>([]);
    const WIDTH_CANVAS = document.getElementById("device-container")?.clientWidth || 526;
    const [record, setRecord] = React.useState(true);
    const isForceTurnOnAudio = assessmentDetails.assessment.audioSetting === EAudioSetting.REQUIRED;
    const audio = new Audio("/sounds/audio-test.mp3");
    const audioContext = new AudioContext();
    const analyser = audioContext.createAnalyser();
    let audioSource: any = null;
    let mediaStreamAudio: any = null;

    const loadAudioDevices = (deviceId: string) => {
        navigator.mediaDevices
            .getUserMedia({
                audio: {
                    deviceId
                }
            })
            .then((mediaStream) => {
                mediaStreamAudio = mediaStream;
            });
    };

    const stopAudioDevices = () => {
        if (mediaStreamAudio) {
            mediaStreamAudio.getAudioTracks()[0].stop();
            // mediaStreamAudio?.forEach((track: any) => {
            //     track.stop();
            // });
        }
    };

    React.useEffect(() => {
        return () => {
            stopAudioDevices();
        };
    }, []);

    React.useEffect(() => {
        navigator.mediaDevices?.addEventListener("devicechange", (event) => {
            // Update list of devices
            getDevices();
        });
        const getDevices = async () => {
            let deviceList = await navigator.mediaDevices?.enumerateDevices();
            setSpeakersDevices(
                deviceList.filter(({ kind, deviceId }) => kind === "audiooutput" && deviceId !== "default")
            );
            setMicrophoneDevices(deviceList.filter(({ kind, deviceId }) => kind === "audioinput"));
        };
        if (navigator.mediaDevices) {
            getDevices();
        }
    }, []);

    React.useEffect(() => {
        if (speakersDevices.length > 0) {
            setSpeakersDeviceId(speakersDevices[0].deviceId);
            onDrawCanvas();
        }
        if (microphoneDevices.length > 0) {
            setMicrophoneDeviceId(microphoneDevices[0].deviceId);
        }
    }, [speakersDevices, microphoneDevices]);

    const onRunSpeakersTest = () => {
        audio.play();
        onDrawCanvas();
    };

    const onDrawCanvas = async () => {
        const sinkId = typeof speakersDeviceId !== "object" ? speakersDeviceId : speakersDevices[0].deviceId;
        // @ts-ignore
        await audioContext.setSinkId(sinkId);
        audio.crossOrigin = "anonymous";
        if (!audioSource) {
            audioSource = audioContext.createMediaElementSource(audio);
            audioSource.connect(analyser);
            audioSource.connect(audioContext.destination);
            analyser.connect(audioContext.destination);
            createVisualization(analyser);
        }
    };

    const createVisualization = (analyser: any) => {
        const canvas = document.getElementById("canvas") as HTMLCanvasElement;
        const canvasCtx = canvas.getContext("2d");
        const WIDTH = WIDTH_CANVAS;
        const HEIGHT = 28; // canvas.height;

        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);
        canvasCtx?.clearRect(0, 0, WIDTH, HEIGHT);
        const draw = () => {
            if (canvasCtx === null) return;
            requestAnimationFrame(draw);
            analyser.getByteTimeDomainData(dataArray);
            canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);
            canvasCtx.lineWidth = 2;
            canvasCtx.strokeStyle = "#21986B";
            canvasCtx.lineCap = "round";
            canvasCtx.fillStyle = "white";
            canvasCtx.beginPath();
            const sliceWidth = (WIDTH * 1.0) / bufferLength;
            let x = 0;
            for (let i = 0; i < bufferLength; i++) {
                const v = dataArray[i] / 128.0;
                const y = (v * HEIGHT) / 2;
                if (i === 0) {
                    canvasCtx.moveTo(x, y);
                } else {
                    canvasCtx.lineTo(x, y);
                }
                x += sliceWidth;
            }
            canvasCtx.lineTo(canvas.width, canvas.height / 2);
            canvasCtx.stroke();
        };
        draw();
    };

    const onNext = React.useCallback(() => {
        stopAudioDevices();
        setRecord(false);
        setStep(STEPS.INTRO);
    }, [setStep]);

    React.useEffect(() => {
        if (record && microphoneDeviceId) {
            loadAudioDevices(microphoneDeviceId as string);
        }
    }, [record, microphoneDeviceId]);

    // React.useEffect(() => {
    //     // const timer = setTimeout(() => setRecord(prev => !prev), 100);
    //     return () => clearTimeout(timer);
    // }, []);

    return (
        <div className="grid grid-cols-1 gap-[32px] bg-white rounded-[12px] px-[32px] py-[54px] shadow-e-03">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-[24px] items-start justify-start">
                <div className="grid grid-cols-1 gap-[16px]" id="device-container">
                    <span className="text-left">
                        <Typography.Title
                            level={3}
                            style={{
                                color: "#110C22",
                                fontWeight: 700,
                                marginBottom: 0,
                                fontSize: "28px",
                                lineHeight: "40px"
                            }}
                        >
                            {i18next.t("testing.checkTheSpeaker")}
                        </Typography.Title>
                    </span>
                    {isForceTurnOnAudio ? (
                        <span className="text-body font-bold text-red-400">
                            {i18next.t("testing.assessmentForceTurnOnSpeaker")}
                        </span>
                    ) : (
                        <span className="text-body text-high-em">{i18next.t("testing.assessmentRequireSpeaker")}</span>
                    )}

                    <WrapperDevices className="grid grid-cols-[1fr_77px] max-md:flex max-md:flex-col gap-[16px]">
                        <HRSelect
                            value={speakersDeviceId}
                            hasSearch={false}
                            icon={<ICSound />}
                            onChange={(value) => {
                                setSpeakersDeviceId(value);
                                loadAudioDevices(value);
                            }}
                            style={{
                                width: "100%",
                                height: "auto",
                                wordWrap: "break-word"
                            }}
                            allowClear={false}
                            options={speakersDevices.map((c) => ({ label: c.label, value: c.deviceId }))}
                        />
                        <span>
                            <HRButton onClick={onRunSpeakersTest} btnType="secondary" btnSize="md">
                                <div className="flex items-center justify-center">
                                    <span>{i18next.t("testing.test")}</span>
                                </div>
                            </HRButton>
                        </span>
                    </WrapperDevices>
                    <canvas id="canvas" width={WIDTH_CANVAS} height={"28px"}></canvas>
                    <div>
                        <Divider style={{ margin: "0", borderColor: "#E2E4EB" }} />
                    </div>
                    <div className="grid grid-cols-1 gap-[24px] items-start justify-start">
                        <div className="grid grid-cols-1 gap-[16px]">
                            <span className="text-left">
                                <Typography.Title
                                    level={3}
                                    style={{
                                        color: "#110C22",
                                        fontWeight: 700,
                                        marginBottom: 0,
                                        fontSize: "28px",
                                        lineHeight: "40px"
                                    }}
                                >
                                    {i18next.t("testing.checkCommunicationDevice")}
                                </Typography.Title>
                            </span>
                            {isForceTurnOnAudio ? (
                                <span className="text-body font-bold text-red-400">
                                    {i18next.t("testing.assessmentForceTurnOnMic")}
                                </span>
                            ) : (
                                <span className="text-body text-high-em">
                                    {i18next.t("testing.assessmentRequireCommunicationDevice")}
                                </span>
                            )}
                            <WrapperDevices className="grid grid-cols-1 gap-[16px]">
                                <HRSelect
                                    value={microphoneDeviceId}
                                    hasSearch={false}
                                    onChange={(value) => {
                                        setMicrophoneDeviceId(value);
                                        loadAudioDevices(value);
                                    }}
                                    icon={<ICMicroPhone />}
                                    allowClear={false}
                                    options={microphoneDevices.map((c) => ({ label: c.label, value: c.deviceId }))}
                                />
                            </WrapperDevices>
                            <div>
                                <ReactMic
                                    record={record}
                                    className="sound-wave w-full"
                                    strokeColor="#21986B"
                                    backgroundColor="white"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-1 gap-[16px]">
                    <div className="rounded-[12px] bg-[#EEF0F6] w-full p-[32px]">
                        <div className="grid grid-cols-1 gap-[16px]">
                            <span className="text-standard-bold text-high-em">
                                {i18next.t("testing.haveIssueWithTheSound")}
                            </span>
                            <span className="text-body text-high-em">{i18next.t("testing.accessAudioDevice")}</span>
                            <span className="text-body text-high-em">{i18next.t("testing.updateLastestVersion")}</span>
                            <span className="text-body text-high-em">{i18next.t("testing.resetYourDevice")}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex items-center justify-center">
                <span>
                    <HRButton
                        onClick={onNext}
                        btnType="primary"
                        style={{
                            backgroundColor: companyProfile?.color?.backgroundColor,
                            borderColor: companyProfile?.color?.backgroundColor,
                            color: companyProfile?.color?.fontColor || "#fff"
                        }}
                    >
                        <div className="flex space-x-2 items-center justify-center">
                            <span>{i18next.t("testing.continue")}</span>
                            <ICNext fill={companyProfile?.color?.fontColor || "#fff"} />
                        </div>
                    </HRButton>
                </span>
            </div>
        </div>
    );
};
